import { useState, useEffect, useRef } from 'react';
import styles from "./index.module.css";
import { Linkify } from './Linkify.js';

export function ChatResponse({ responseText }) {
    const [response, setResponse] = useState("");
  const componentUiRef = useRef();

    useEffect(() => {
      setResponse(responseText);
      componentUiRef.current.scrollIntoView({ behavior: 'smooth' });
    }, [responseText]);

    function clearReponse() {
      console.log("clearReponse clicked");
      setResponse('');
    }

    return (
      <div className={styles['gpt-response-panel']} ref={componentUiRef}>
        <label className={styles['field-label']}>Here is my advice</label>
        <div className={styles['response-content']}>{
          response ?
            <Linkify>{response}</Linkify> :
            <span className={styles['placeholder-text']}>My detailed response will be displayed here</span>}
        </div>
        <div className={styles['response-section-toolbar']}>
          <button
            type="button"
            id="clear-response"
            onClick={clearReponse}
            className={styles['primary-btn']}>Clear</button>
        </div>
      </div>
    )
}