import DOMPurify from 'dompurify'

const urlRegex = /(https?:\/\/[^\s]+)/g;

export const Linkify = ({children}) => {

  const formatedText = children.replace(urlRegex, function(url) {
    return `<a href="${url}" target="_blank">${url}</a>`
  })

  const html = DOMPurify.sanitize(formatedText, { ADD_ATTR: ['target'] })
  return (<span style={{ "whiteSpace": "pre-wrap" }} dangerouslySetInnerHTML={{__html: html}} />)
}
