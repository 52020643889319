import { useState, useEffect } from 'react';
import styles from "./index.module.css";
export function TopicSelecttor({
  onSetSelectedTopic,
  onSetIsFormToBeResetOnTopicChange,
  onResponseLimitChange,
  defaultGptResponseLimit
}) {

  const [isResetOtherFieldsPolicyOn, setIsResetOtherFieldsPolicyOn] = useState(false);
  const defaultTopic = 'CAREER'
  const [topic, setTopic] = useState(defaultTopic)

  useEffect(() => {
    onSetSelectedTopic(topic);
  }, [onSetSelectedTopic, topic]);

    function onChangeValue(e) {
        let topic = e.target.value;
        setTopic(topic);
      }

  function onResetOtherFieldsPolicyChange() {
    setIsResetOtherFieldsPolicyOn(!isResetOtherFieldsPolicyOn);
    onSetIsFormToBeResetOnTopicChange(!isResetOtherFieldsPolicyOn);
  }

  function setResponseLimitInParent(value) {
    onResponseLimitChange(parseInt(value));
  }

    return (
        <div className={styles['form-item-wrapper']}>
          <div>
            <main className={`${styles.main} ${styles['topics-wrapper']}`}>
                <h3>Please Select Topic to talk about</h3>
                <br />
                <table>
                  <tbody>
                      <tr>
                          <td>
                              <input type="radio" value="CAREER" name="topic" checked={topic === "CAREER"} onChange={onChangeValue} /> Career
                          </td>
                          <td>
                              <input type="radio" value="ENTREPRENEURSHIP" name="topic" checked={topic === "ENTREPRENEURSHIP"} onChange={onChangeValue} /> Entrepreneurship
                          </td>
                          <td>
                              <input type="radio" value="FINANCE" name="topic" checked={topic === "FINANCE"} onChange={onChangeValue} /> Finance
                          </td>
                          <td>
                              <input type="radio" value="HEALTH" name="topic" checked={topic === "HEALTH"} onChange={onChangeValue} /> Health
                          </td>
                          <td>
                              <input type="radio" value="TRAVEL" name="topic" checked={topic === "TRAVEL"} onChange={onChangeValue} /> Travel
                          </td>
                      </tr>
                      <tr></tr>
                      <tr>
                          <td>
                              <input type="radio" value="NUTRITION" name="topic" checked={topic === "NUTRITION"} onChange={onChangeValue} /> Nutrition
                          </td>
                          <td>
                              <input type="radio" value="DATING" name="topic" checked={topic === "DATING"} onChange={onChangeValue} /> Dating & Partnership
                          </td>
                          <td>
                              <input type="radio" value="FAMILY" name="topic" checked={topic === "FAMILY"} onChange={onChangeValue} /> Family
                          </td>
                          <td>
                              <input type="radio" value="STYLE" name="topic" checked={topic === "STYLE"} onChange={onChangeValue} /> Beauty & Style
                          </td>
                          <td>
                              <input type="radio" value="FUN" name="topic" checked={topic === "FUN"} onChange={onChangeValue} /> Fun
                          </td>
                      </tr>
                  </tbody>
                </table>
            </main>
            <div className={styles['form-reset-setting-wrapper']}>
              <input
                type="checkbox"
                id="reset-fields-checkbox"
                checked={isResetOtherFieldsPolicyOn}
                onChange={onResetOtherFieldsPolicyChange} />
              <label htmlFor="reset-fields-checkbox">Reset question and hint fields when I change a topic!</label>
            </div>
            <div className={styles['response-limit-setting-wrapper']}>
              <select
                id="response-limit-select"
                defaultValue={defaultGptResponseLimit}
                onChange={(e) => setResponseLimitInParent(e.target.value)}>
                {
                  [100, 200, 300, 400, 500, 600, 700, 800, 900, 1000].map(limit => {
                    return <option
                      key={limit}
                      value={limit}>{limit}</option>
                  })
                }
              </select>
              <label htmlFor="response-limit-select">Token limit on response length</label>
            </div>
          </div>
        </div>
    )
};
