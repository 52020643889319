import apiClient from "./apiClient";

export async function GenerateChatResponse(reqst) {

    const topic = reqst.body.topic || '';
    if (process.env.REACT_APP_ENVIRONMENT === 'development' && topic.trim().length === 0) {
      throw new Error("Please select a valid topic");
    }

    const question = reqst.body.question || '';
    if (question.trim().length === 0) {
      throw new Error("Please enter a valid question");
    }

    try {
        const response = await apiClient
            .post("/api/ask-rocket/query", reqst.body);
        return {
          result: response.data
        };
    } catch (error) {
        // Consider adjusting the error handling logic for your use case
        throw new Error(error.message)
    }
}
