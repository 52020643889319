import { useState, useEffect, useRef } from 'react';
import { TopicSelecttor } from "./TopicSelector";
import { AskQuestion01 } from './AskQuestion01';
import { ChatResponse } from './ChatResponse';
import { GenerateChatResponse } from './generate01';
import { PromptInput } from './PromptInput';
import { LoadingUi } from './loading/LoadingUi';

import styles from "./index.module.css";

export default function Rocket50Guide() {
  const [isLoading, setIsLoading] = useState(false);
  const defaultGptResponseLimit = 600;
  const [gptResponseLimit, setGptResponseLimit] = useState(defaultGptResponseLimit)
  const prevTopic = useRef();
  const [topicWhileFormResetPolicyIsOn, setTopicWhileFormResetPolicyIsOn] = useState('');
  const [isFormToBeResetOnTopicChange, setIsFormToBeResetOnTopicChange] = useState(false);
    const [selectedTopic, setSelectedTopic] = useState(null);
    const [responseText, setResponseText] = useState('');
    const [promptValue, setPromptValue] = useState('');

    useEffect(() => {
      if(prevTopic.current !== selectedTopic) {
        // 'useEffect' hook is triggered by topic change
        if(isFormToBeResetOnTopicChange) {
          setTopicWhileFormResetPolicyIsOn(selectedTopic);
        }
      }
      prevTopic.current = selectedTopic;
    }, [selectedTopic, isFormToBeResetOnTopicChange])

    async function onSubmit(question, contextPrompt) {
      setIsLoading(true);
      try {
        const requestOptions = {
          body: {
            contextPrompt,
            topic: selectedTopic,
            question: question,
            prompt: promptValue,
            limit: gptResponseLimit
          }
        };
        const { result } = await GenerateChatResponse(requestOptions);
        setResponseText(result);
        setIsLoading(false);
      } catch(error) {
        // Consider implementing your own error handling logic here
        alert(error.message);
        setIsLoading(false);
      }
    }

    return (
        <div className={styles['layout-wrapper']}>
          <LoadingUi show={isLoading} />
          { process.env.REACT_APP_ENVIRONMENT === 'development' &&
            <TopicSelecttor
                onSetSelectedTopic={setSelectedTopic}
                onSetIsFormToBeResetOnTopicChange={setIsFormToBeResetOnTopicChange}
                onResponseLimitChange={setGptResponseLimit}
                defaultGptResponseLimit={defaultGptResponseLimit} /> }
          <AskQuestion01
              onSubmitClick={onSubmit}
              resetField={topicWhileFormResetPolicyIsOn} />
          <ChatResponse responseText={responseText} />
          { process.env.REACT_APP_ENVIRONMENT === 'development' &&
            <PromptInput
              onPromptChange={setPromptValue}
              resetField={topicWhileFormResetPolicyIsOn} /> }
        </div>
    );
}