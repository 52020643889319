import { useState, useEffect } from 'react';
import styles from "./index.module.css";

export function PromptInput({ onPromptChange, resetField }) {
  const [text, setText] = useState('');


  function onClear() {
    setText('');
    onPromptChange('');
  }
  function onInput(e) {
    setText(e.target.value);
    onPromptChange(e.target.value);
  }

  useEffect(() => {
    setText('');
  }, [resetField]);

  return (
    <div className={styles['long-text-form-item']}>
      <div className={`${styles['form-item-wrapper']}`}>
        <label
          htmlFor="prompt"
          className={styles['field-label']} >Add a smart prompt (only available in dev env)</label>
        <textarea
          id="prompt"
          autoComplete={"on"}
          autoCorrect={"on"}
          spellchek={"true"}
          maxLength={300}
          rows={5}
          cols={80}
          wrap={"on"}
          value={text}
          onChange={onInput}
          placeholder="Your smart prompt goes here.. (300 characters max)"></textarea>
        <div className={styles['question-section-toolbar']}>
          <button type="button" onClick={onClear} >Clear</button>
        </div>
      </div>
    </div>
    );
}