import { useState, useEffect, useRef } from 'react';
import styles from "./LoadingUi.module.css";

export function LoadingUi({ show }) {
  const [counter, setCounter] = useState(0);
  let seconds = 0;
  const timerIdRef = useRef(0);

  function startCounter() {
    seconds = 0;
    setCounter(seconds);
    timerIdRef.current = setInterval(() => {
      setCounter(++seconds);
    }, 1000);
  }
  function stopCounter() {
    clearInterval(timerIdRef.current);
  }

  useEffect(() => {
    if(show) {
      startCounter();
    } else {
      stopCounter();
    }
  }, [show]);

  return (
    <div
      className={`${styles['lds-default']} ${styles['page-center']} ${show && counter > 0 ? '' : styles['hidden']}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
      <span className={styles['inner-el']}>{counter}</span>
    </div>
    );
}