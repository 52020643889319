import Axios from "axios";

const apiClient = Axios.create({
  baseURL: '',
  keepAlive: true,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  },
});

export const fetchData = async (urlEndpoint) => {
  try {
    const response = await apiClient.get(urlEndpoint)
    return {
      result: response.data
    };
  } catch (error) {
    // Consider adjusting the error handling logic for your use case
    console.log(error)
    throw new Error(error.message)
  }
}

export default apiClient;