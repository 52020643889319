import { useState, useEffect } from 'react';
import styles from "./index.module.css";
import RadioSelect from './radio-select/RadioSelect';
import { fetchData } from "./apiClient";

export function AskQuestion01({ onSubmitClick, resetField }) {

  const [contextPrompts, setContextPrompts] = useState([]);
  const [selectedContextPrompt, setSelectedContextPrompt] = useState(null);
  const [userQuestion, setUserQuestion] = useState('');

    function onClear() {
        console.log("clearQuestion clicked");
        setUserQuestion('');
    }

    function onInput(e) {
        setUserQuestion(e.currentTarget.value);
    }

    function onSubmit(e) {
        e.preventDefault();
        onSubmitClick(userQuestion, selectedContextPrompt);
    }

  useEffect(() => {
    setUserQuestion('');
  }, [resetField]);

  useEffect(() => {
    async function fetchContextPrompts() {
      try {
        const contextPromptsApiResponse = await fetchData("/api/ask-rocket/context-prompts")
        setContextPrompts(contextPromptsApiResponse.result)
      } catch(err) {}
    }
    fetchContextPrompts()
  }, []);

    return (
            <div className={`${styles['search-panel']}`}>
              <div className={`${styles['form-item-wrapper']}`}>
                <label className={styles['field-label']}>For focused job search topics, choose a category below</label>
                <RadioSelect
                  radioGroupName={'contextPromptSelector'}
                  options={contextPrompts}
                  passSelectedOption={setSelectedContextPrompt}/>
              </div>
              <div className={`${styles['form-item-wrapper']}`}>
                <label
                  htmlFor="question"
                  className={styles['field-label']}>Ask your question</label>
                <textarea
                  id="question"
                  name="question"
                  autoComplete={"on"}
                  autoCorrect={"on"}
                  spellchek={"true"}
                  maxLength={300}
                  value={userQuestion}
                  onChange={onInput}
                  placeholder="What would you like to ask about? (300 characters max)"
                  rows={5}
                  cols={80}
                  wrap={"on"} />
                <div className={styles['question-section-toolbar']}>
                    <button
                      type="button"
                      id="clear-question"
                      onClick={onClear}
                      className={styles['primary-btn']}>Clear</button>
                    <button
                      type="button"
                      id="submit-question"
                      onClick={onSubmit}
                      className={styles['primary-btn']}>Submit</button>
                </div>
              </div>
            </div>
    )
}